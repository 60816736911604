import { UpdateUserForm } from "src/app/modules/user/forms/update-user-form";
import { Address, IAddress } from "../addresses/address";
import { CellGroup } from "../cell-groups/cell-group";
import { Center, ICenter } from "../centers/center";
import { UserExportModel } from "../exports/user-export-model";
import { Health, IHealth } from "../healths/health";
import { IService, Service } from "../services/service";
import { IAgent, Agent } from "./agent";
import { Availability, IAvailability } from "./availability";
import * as dayjs from "dayjs";
import * as duration from "dayjs/plugin/duration";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(duration);
dayjs.extend(utc);

export interface IRole{
    roleId:Number;
    name:string;
}
export interface IProfile {
    addresses: IAddress[];
    agents: IAgent[];
    assignees: any;
    availabilities: IAvailability[];
    cellGroups: CellGroup[];
    center: Center;
    chineseName: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    dateOfBirth: Date;
    district: string;
    educationLevel: string;
    englishName: string;
    gender: string;
    hasSimCard: boolean;
    healths: IHealth[];
    literacy: string;
    memberId: string;
    mobilePhoneModel: string;
    phone: string;
    services: IService[];
    timeBalance: number;
    userName: string;
    uuId: string;
    staffCenters: ICenter[];
    remark:string;
    allowApproval: boolean;
    isFreeze:boolean;
}

export class Profile {
    addresses: Address[];
    agents: Agent[];
    assignees: any;
    availabilities: Availability[];
    cellGroups: CellGroup[];
    center: Center;
    chineseName: string;
    contactPersonName: string;
    contactPersonPhoneNumber: string;
    dateOfBirth: Date;
    district: string;
    educationLevel: string;
    englishName: string;
    gender: string;
    hasSimCard: boolean;
    healths: Health[];
    literacy: string;
    memberId: string;
    mobilePhoneModel: string;
    phone: string;
    services: Service[];
    timeBalance: number;
    userName: string;
    uuId: string;
    staffCenters: Center[];
    allowApproval: boolean;

    selected: boolean;
    disabled: boolean;
    form: UpdateUserForm;
    remark:string;
    isFreeze:boolean;
    constructor(profile: IProfile) {
        this.uuId = profile.uuId;
        this.memberId = profile.memberId;
        this.chineseName = profile.chineseName;
        this.englishName = profile.englishName;
        this.center = profile.center;
        this.dateOfBirth = new Date(profile.dateOfBirth + "Z");
        this.district = profile.district;
        this.educationLevel = profile.educationLevel;
        this.gender = profile.gender;
        this.hasSimCard = profile.hasSimCard;
        this.literacy = profile.literacy;
        this.mobilePhoneModel = profile.mobilePhoneModel;
        this.phone = profile.phone;
        this.timeBalance = profile.timeBalance;
        this.userName = profile.userName;
        this.remark=profile.remark;
        // this.lastUpdate = profile.lastUpdate ? new Date(profile.lastUpdate + 'Z') : null;
        this.addresses = profile.addresses ? profile.addresses.map(address => new Address(address)) : [];
        this.healths = profile.healths ? profile.healths.map(health => new Health(health)) : [];
        this.services = profile.services ? profile.services.map(service => new Service(service)) : [];
        this.staffCenters = profile.staffCenters ? profile.staffCenters.map(c => new Center(c)) : [];
        this.contactPersonName = profile.contactPersonName;
        this.contactPersonPhoneNumber = profile.contactPersonPhoneNumber;
        this.availabilities = profile.availabilities ? profile.availabilities.map(a => new Availability(a)) : [];
        // this.extraPersonJson = profile.extraPersonJson == null ? null : typeof (profile.extraPersonJson) == "object" ? profile.extraPersonJson : JSON.parse(profile.extraPersonJson);
        this.cellGroups = profile.cellGroups ? profile.cellGroups.map(group => new CellGroup(group)) : [];
        this.agents = profile.agents.map(agent => new Agent(agent));
        // this.assignees = profile.assignees.map(assignee => new Assignee(assignee));
        this.allowApproval = profile.allowApproval;
        this.selected = false;
        this.form = new UpdateUserForm(this);
        this.disabled = false;
        this.isFreeze=profile.isFreeze;
    }

    get age(): number {
      return dayjs.duration(dayjs.utc().diff(this.dateOfBirth)).years();
    }

    get defaultAddress(): Address | undefined {
        return this.addresses.find(address => address.isDefault);
    }

    get defaultAddressString(): string {
        return this.defaultAddress ? this.defaultAddress.toString() : "-";
    }

    getUserExportModel(): UserExportModel {
        return {
            "會員編號*": this.memberId,
            "會員中文姓名*": this.chineseName,
            "會員英文姓名*": this.englishName,
            "性別*": this.gender == "M" ? "男" : this.gender == "F" ? "女" : null,
            "中心編號*（須和後台設置中的中心編號一致）": this.center.centerCode,
            "出生日期*（YYYY-MM-DD）": dayjs(this.dateOfBirth).format("YYYY-MM-DD"),
            "電話號碼*": this.phone,
            "登入帳號*": this.userName,
            "會員密碼*": null,
            "網卡配備*（是否有數據可上網）": this.hasSimCard ? "是" : "不是",
            "讀寫能力*": this.literacy == "CanReadAndWrite" ? "能讀能寫" :
                this.literacy == "CanRead" ? "只能讀，不能寫" : "不懂文字",
            "教育程度*": this.educationLevel == "University" ? "大學或以上" :
                this.educationLevel == "Secondary" ? "中學" :
                    this.educationLevel == "Primary" ? "小學" :
                        "不適用",
            "住址（地區）*": this.defaultAddress?.region.name,
            "住址（分區）*": this.defaultAddress?.district.name,
            "住址（區）": this.defaultAddress?.subDistrict.name,
            "住址（街道）": this.defaultAddress?.street.name,
            "住址（街號）": this.defaultAddress?.streetNumber.name,
            "屋苑／屋邨": this.defaultAddress?.estate.name,
            "住址（大廈/期/座）": this.defaultAddress?.block.name,
            "樓層": this.defaultAddress?.floor.name,
            "單位": this.defaultAddress?.room.name,
            "居住條件（須和後台設置中的資料一致）": this.defaultAddress?.livings.map(living => living.name).join(", "),
            "身體狀況（須和後台設置中的資料一致）": this.healths.map(health => health.name).join(", "),
            "緊急聯絡人名稱": this.contactPersonName,
            "緊急聯絡人電話": this.contactPersonPhoneNumber,
            "可提供服務（義工可填，用逗號隔開，字眼須和後台設置中的可提供服務細項一致）": this.services.map(service => service.name).join(", "),
            "細胞小組（填寫組名即可，可屬於多個細胞小組並用逗號隔開，字眼和後台設置一致，義工配對推薦時會優先推薦同組成員）": this.cellGroups.map(cellGroup => cellGroup.name).join(", "),
            "電話型號": this.mobilePhoneModel,
            "智能電話？（是否可安裝使用app）": this.hasSimCard ? "是" : "不是",
            "時分餘額(唯讀)": this.timeBalance,
            "備註":this.remark
        } as any;
    }
}
