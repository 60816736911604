import { Center, ICenter } from "../centers/center";
import { UpdateProductForm } from "src/app/modules/redeem/forms/update-product-form"
import { IRedeem, Redeem } from "./redeem";
import { ProductExportModel } from "../exports/product-export-model";
import * as dayjs from "dayjs";

export interface IProduct {
    active: boolean;
    brand: string;
    centers: ICenter[];
    closeDate: Date;
    description: string;
    format: string;
    id: number;
    imagePath: string;
    isAnswerQuestionRequired: boolean;
    launchDate: Date;
    maximumQuantityPerPerson: number;
    price: number;
    productName: string;
    redeems: IRedeem[];
    stock: number;
    uuId: string;
    createdAt: Date;
    createdBy: string;
    target: string;
    pendingCount:number;
}

export class Product {
    active: boolean;
    brand: string;
    centers: Center[];
    closeDate: Date;
    description: string;
    format: string;
    id: number;
    imagePath: string;
    isAnswerQuestionRequired: boolean;
    launchDate: Date;
    maximumQuantityPerPerson: number;
    price: number;
    productName: string;
    redeems: Redeem[];
    stock: number;
    uuId: string;
    createdAt: Date;
    createdBy: string;
    target: string;
    pendingCount:number;
    form: UpdateProductForm;

    constructor(product: IProduct) {
        this.active = product.active;
        this.brand = product.brand;
        this.centers = product.centers.map(center => new Center(center));
        this.closeDate = new Date(product.closeDate + 'Z');
        this.description = product.description;
        this.format = product.format;
        this.id = product.id;
        this.imagePath = product.imagePath;
        this.isAnswerQuestionRequired = product.isAnswerQuestionRequired;
        this.launchDate = new Date(product.launchDate + 'Z');
        this.maximumQuantityPerPerson = product.maximumQuantityPerPerson;
        this.price = product.price;
        this.productName = product.productName;
        this.redeems = product.redeems.map(redeem => new Redeem(redeem));
        this.stock = product.stock;
        this.uuId = product.uuId;
        this.createdAt = new Date(product.createdAt + 'Z');
        this.createdBy = product.createdBy;
        this.target = product.target;
        this.form = new UpdateProductForm(this);
        this.pendingCount=product.pendingCount;
    }

    getExportModel(): ProductExportModel {
        return {
            "獎勵項目編號": this.id.toString(),
            "獎勵標題": this.productName,
            "來源": this.brand,
            "參與類型": this.format,
            "發布日期": dayjs(this.launchDate).format("YYYY-MM-DD"),
            "截止日期": dayjs(this.closeDate).format("YYYY-MM-DD"),
            "設定數量": (this.stock + this.redeems.map(redeem => redeem.quantity).reduce((sum, value) => sum += value, 0)).toString(),
            "庫存餘額": this.stock.toString(),
            "已兌換用戶人數": this.redeems.length.toString(),
            "已兌換獎勵數量": this.redeems.map(redeem => redeem.quantity).reduce((sum, value) => sum += value, 0).toString(),
            "狀態": this.isPending ? "等候發布" : this.isLaunched ? "已發布" : this.isClosed ? "已結束" : ""
        }
    }
    get state():string{
        return this.isPending ? "等候發布" : this.isLaunched ? "已發布" : this.isClosed ? "已結束" : ""
    }
    get isPending(): boolean {
        return  this.stock != 0 && this.pendingCount>0;
    }

    get isLaunched(): boolean {
        return this.launchDate < new Date();
    }

    get isOutOfStock(): boolean {
        return this.stock == 0 && this.closeDate > new Date();
    }

    get isClosed(): boolean {
        return this.closeDate < new Date();
    }

    get quantity(): number {
        return (this.stock + this.redeems.map(redeem => redeem.quantity).reduce((sum, value) => sum += value, 0));
    }

}
