import { FormControl, Validators } from "@angular/forms";
import { AppSetting } from "src/app/backend/app-setting";
import { DonationExportModel } from "../exports/donation-export-model";
import { IProfile, Profile } from "../profiles/profile";
import * as dayjs from "dayjs";

export interface ITransaction {
    id: number;
    amount: number;
    date: Date;
    note: string;
    status: string;
    type: string;
    uuId: string;
    transferee: ITransactionProfile;
    transferer: ITransactionProfile;
}


export class Transaction {
    public id: number;
    public amount: number;
    public date: Date;
    public note: string;
    public status: string;
    public type: string;
    public uuId: string;
    public transferee: ITransactionProfile;
    public transferer: ITransactionProfile;

    selected: boolean;
    editing: boolean;

    form: FormControl;

    constructor(transcation: ITransaction) {
        this.id = transcation.id;
        this.amount = transcation.amount;
        this.date = new Date(transcation.date + 'Z');
        this.note = transcation.note;
        this.status = transcation.status;
        this.type = transcation.type;
        this.uuId = transcation.uuId;
        this.transferer = transcation.transferer;
        this.transferee = transcation.transferee;
        this.selected = false;
        this.editing = false;

        this.form = new FormControl(this.amount, [Validators.required, Validators.min(0)]);
    }

    getDonationExportModel(): DonationExportModel {
        return {
            "紀錄編號": this.id.toString(),
            "會員編號": this.transferer.uuId == AppSetting.centerUUId ? this.transferee.memberId : this.transferer.memberId,
            "會員姓名": this.transferer.uuId == AppSetting.centerUUId ? this.transferee.chineseName : this.transferer.chineseName,
            "捐分類型": this.transferer.uuId == AppSetting.centerUUId ? "中心捐分" : "會員捐分",
            "捐分日期（YYYY-MM-DD）": dayjs(this.date).format("YYYY-MM-DD"),
            "交易時分（捐出/受贈的時分）": (this.transferer.uuId == AppSetting.centerUUId ? "+" : "-") + this.amount.toString(),
            "備註": this.note
        }
    }
}

export interface ITransactionProfile {
    uuId: string;
    chineseName: string;
    memberId: string;
}
